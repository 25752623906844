import React, { useEffect, useState } from "react"

const InstallationOverview = () => {
  const [projects, setProjects] = useState([])
  let installerId
  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    installerId = params.get("installerId")
  }

  const getProjects = async () => {
    const response = await fetch(
      `/.netlify/functions/installation-overview?installerId=${installerId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const { projects } = await response.json()
    console.log("🚀 ~ projects:", projects)
    setProjects(projects)
  }

  useEffect(() => {
    console.log("get projects")

    getProjects()
  }, [])

  return (
    <div style={{ padding: 30 }}>
      <div style={{ paddingBottom: 20 }}>
        Installation overview for {installerId}
      </div>
      <table className="installation-overview" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Type</th>
            <th>Installer</th>
            <th>Claim</th>
            <th>Notes</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Project</th>
            <th>Where</th>
            <th>Pick up</th>
            <th>Gardin Ready</th>
            <th>Skinne ready</th>
            <th>Customer ready</th>
          </tr>
        </thead>
        <tbody>
          {projects &&
            projects.map((project, index) => {
              return (
                <React.Fragment key={project.id}>
                  {project.week !== projects[index - 1]?.week && (
                    <tr>
                      <td
                        colSpan="18"
                        style={{
                          fontWeight: "bold",
                          background: "#eee",
                          borderTop: "2px solid #333",
                        }}
                      >
                        {project.week}
                      </td>
                    </tr>
                  )}
                  <tr key={project.id}>
                    <td>{project.dateFormatted}</td>
                    <td>{project.timeFormatted}</td>
                    <td>{project.type}</td>
                    <td>{project.curtainInstaller}</td>
                    <td>{project.claim ? "YES" : "-"}</td>
                    <td>{project.note}</td>
                    <td>
                      <a
                        href={`/internal-project?id=${project.id}`}
                        target="_blank"
                      >
                        {project.customerName}
                      </a>
                    </td>
                    <td>{project.phoneNumber}</td>
                    <td>
                      <a
                        href={`/internal-project?id=${project.id}`}
                        target="_blank"
                      >
                        {project.id}
                      </a>
                    </td>
                    <td>
                      <a href={project.appleMapsLink} target="_blank">
                        {project.address}
                      </a>
                    </td>
                    <td>
                      <div>{project.locationOfRails}</div>
                      <div>{project.locationOfCurtains}</div>
                    </td>

                    <td>{project.pagunetteOrdered ? "OK" : "-"}</td>
                    <td>{project.aluproffOrdered ? "OK" : "-"}</td>
                    <td>
                      {project.installationInformationConfirmed ? "OK" : "-"}
                    </td>
                  </tr>
                </React.Fragment>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default InstallationOverview
